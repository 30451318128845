import styled from 'styled-components'
import { up } from 'src/utils/media'

interface Props {
  float?: string
}

const StyledH1 = styled.h1<Props>`
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.bodyTextDark};
  font-size: 1.9rem;
  letter-spacing: .95em;
  line-height: 1.25;
  transition: all 1s ease;
  margin-right: -1em;

  ${up('mobile')} {
    font-size: 2.4rem;
  }

  ${up('desktop')} {
    font-size: 3rem;
  }

  ${up('largeDesktop')} {
    font-size: 7rem;
  }

  ${props => props.float && `text-align: ${props.float};`}
`

export default StyledH1
