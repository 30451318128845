import styled from 'styled-components'
import { up, down } from 'src/utils/media'

const Wrapper = styled.section`
  flex-flow: row;
  justify-content: space-between;
  height: inherit;
  min-height: 100%;
  margin: auto;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 4rem 0;

  ${down('tablet')} {
    @media only screen and (min-height: 600px) {
      padding: 0;
      height: calc(100vh - 83px);
      height: calc(var(--vh) * 100 - 83px);
      align-items: center;
    }

    & > div {
      width: ${props => props.theme.containerWidth.sm};
    }

    & > .mobile {
      height: 100%;

      & > .grid-row {
        flex-flow: column;
        height: 100%;

        & > .grid-column {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  ${up('tablet')} {
    padding: 11.5rem 0 8rem 0;
  }

  ${up('desktop')} {
    padding: 19rem 0 10rem 0;
  }

  ${up('largeDesktop')} {
    padding: 28rem 0 10rem 0;
  }

  & .grid-row {
    padding: 0;
  }

  & .grid-column {
    position: relative;

    & > h1 {
      /* cursor: pointer; */

      ${up('tablet')} {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      & > a {
        text-decoration: none;
        outline: none;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
      }

      &::after {

        ${up('tablet')} {
          content:'';
          position: absolute;
          width: 10rem;
          height: 2px;
          top: 50%;
          background-color: ${props => props.theme.colors.separator[300]};
          transform: translateY(-50%);
          transition: all .7s ease-out;
          z-index: -1;
        }

        ${up('desktop')} {
          width: 13rem;
        }

        ${up('largeDesktop')} {
          width: 26rem;
        }

      }

      &:hover {
        ${up('tablet')} {
          letter-spacing: 1.15em;
        }

        &::after {
          ${up('tablet')} {
            width: 32rem;
          }

          ${up('desktop')} {
            width: 40rem;
          }

          ${up('largeDesktop')} {
            width: 110rem;
          }

        }
      }

    }

    &:nth-child(1) {
      & > h1 {

        ${up('tablet')} {
          left: 14rem;

          &:hover {
            left: 10rem;
          }

          &::after {
            left: -14rem;
          }
        }

        ${up('desktop')} {
          left: 21rem;
          &:hover {
            left: 16rem;
          }
          &::after {
            left: -21rem;
          }
        }

        ${up('largeDesktop')} {
          left: 53rem;
          &:hover {
            left: 40rem;
          }
          &::after {
            left: -53rem;
          }
        }

      }
    }

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-last-child(1) {
      & > h1 {

        ${up('tablet')} {
          right: 14rem;

          &:hover {
            right: 10rem;
          }

          &::after {
            right: -14rem;
          }
        }

        ${up('desktop')} {
          right: 21rem;
          &:hover {
            right: 16rem;
          }
          &::after {
            right: -21rem;
          }
        }

        ${up('largeDesktop')} {
          right: 53rem;
          &:hover {
            right: 40rem;
          }
          &::after {
            right: -53rem;
          }
        }

      }
    }

    ${down('desktop')} {
      & h1 + h4 {
        margin-top: 2em;
      }
    }

    ${down('tablet')} {
      & h1 + h4 {
        margin-top: .75em;

        @media only screen and (min-height: 600px) {
          margin-top: 1.375em;
        }
      }
    }

  }

  .square-illustration {
    display: flex;
    min-height: 9.6rem;
    max-height: 9.6rem;
    height: 100%;
    width: auto;
    margin: 3rem auto;

    @media only screen and (min-height: 600px) {
      max-height: 15rem;
    }

    ${up('mobile')} {
      min-height: 15rem;
      max-height: 19rem;
      margin: 15rem auto;
    }
  }

  video {
    margin: 0 auto;
    vertical-align: middle;
    width: 0;
    height: 0;

    --videoSize: ${props => props.theme.videoSize};

    ${up('tablet')} {
      width: 100%;
      height: 100%;
      max-width: calc(var(--videoSize) - 16rem);
      max-height: calc(var(--videoSize) - 16rem);
      min-width: 32rem;
      min-height: 32rem;
    }

    ${up('desktop')} {
      max-width: calc(var(--videoSize) - 18rem);
      max-height: calc(var(--videoSize) - 18rem);
      min-width: 58rem;
      min-height: 58rem;
    }

    ${up('largeDesktop')} {
      max-width: calc(var(--videoSize) - 56rem);
      max-height: calc(var(--videoSize) - 56rem);
      min-width: 148rem;
      min-height: 148rem;
    }

  }

`

export default Wrapper
