import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  flex-flow: row;
  margin: auto;
  position: absolute;
  top: 9rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  z-index: 2;

  ${up('tablet')} {
    top: 7rem;
    max-width: 52rem;
  }

  ${up('desktop')} {
    top: 9rem;
    max-width: 52rem;
  }

  ${up('largeDesktop')} {
    top: 28rem;
    max-width: 112rem;
  }

`

export default Wrapper
