import React from 'react'
import StyledH1 from './style'

interface Props {
  float?: string
  className?: string
  children: React.ReactNode
}

const H1 = (props: Props) => {
  return (
    <StyledH1
      float={props.float}
      className={props.className}
    >
      {props.children}
    </StyledH1>
  )
}

export default H1
