import React from 'react'
import { PageRendererProps } from 'gatsby'
import Layout from 'src/layout'
import HeroSection from 'src/sections/Hero'
import HeroCaptionSection from 'src/sections/HeroCaption'
import SEO from 'src/components/SEO'


const IndexPage: React.FC<PageRendererProps> = props => {
  return (
    <Layout>
      <SEO url={props.location.href}/>
      <main className="centered">
        <HeroCaptionSection />
        <HeroSection />
      </main>
      <div className="bar" style={{ zIndex: -1 }} />
    </Layout>
  )
}

export default IndexPage
