import React from 'react'
import { Link } from 'gatsby'
import { Grid, Row, Col } from '@baevra/grid'
import H1 from 'src/components/Typography/H1'
import H4 from 'src/components/Typography/H4'
import Wrapper from './style'

const HeroSection: React.FC = () => {
  return (
    <Wrapper>
      <Grid xsColumnGap={0} className="desktop">
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={4}>
            <H1>
              <Link to="/expositions">ВЫСТАВКИ</Link>
            </H1>
          </Col>
          <Col xs={12} lg={4}>
            <video
              loop
              autoPlay
              muted
              playsInline
              poster={require('src/static/video/firstframe.jpg')}
            >
              <source src={require('src/static/video/Main.mp4')} type="video/mp4" />
              <source src={require('src/static/video/Main.webm')} type="video/webm" />
              <source src={require('src/static/video/Main.ogv')} type="video/ogg" />
            </video>
          </Col>
          <Col xs={12} lg={4}>
            <H1>
              <a href="https://shop.psgallery.ru/">МАГАЗИН</a>
            </H1>
          </Col>
        </Row>
      </Grid>
      <Grid xsColumnGap={0} className="mobile">
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={4}>
            <H1 float="center">
              <Link to="/expositions">ВЫСТАВКИ</Link>
            </H1>
            <H4 float="center">
              Cоздаём <i>по-настоящему</i> красивые<br/>
              <i>выставочные проекты</i>
            </H4>
          </Col>
          <Col xs={12} lg={4}>
           <img
              className="square-illustration"
              srcSet={`${require('src/static/images/SquareIllustration.png')},
                       ${require('src/static/images/SquareIllustration@2x.png')} 2x,
                       ${require('src/static/images/SquareIllustration@3x.png')} 3x`}
              src={require('src/static/images/SquareIllustration.png')}
              alt="PS Gallery"
            />
          </Col>
          <Col xs={12} lg={4}>
            <H1 float="center">
              <a href="https://shop.psgallery.ru/">МАГАЗИН</a>
            </H1>
            <H4 float="center">
              Дарим возможность<br/>
              <i>приобретения</i> в личную коллекцию<br/>
              <i>произведений искусства</i>
            </H4>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default HeroSection
