import React from 'react'
import H4 from 'src/components/Typography/H4'
import Wrapper from './style'

const HeroCaptionSection = () => {
  return (
    <Wrapper className="desktop">
      <H4 float="center" className="main-caption">
        Cоздаём впечатляющие <i>выставочные проекты</i><br/>
        и дарим <i>возможность приобретения</i> в личную коллекцию<br/>
        <i>произведений искусства</i>
      </H4>
    </Wrapper>
  )
}

export default HeroCaptionSection
